import GATSBY_COMPILED_MDX from "/opt/build/repo/game-pages/CampingGame/ReturnToCamp/TryToSleep/FightMonstersWithKnives/index.mdx";
import React from 'react';
import Seo from '../../components/Seo';
import useSound from 'use-sound';
import {Howler} from 'howler';
import Layout from '../../components/Layout';
import {saveGameName, saveLevelState} from '../../SaveState';
var GamePage = function GamePage(_ref) {
  var _data$mdx$frontmatter;
  var data = _ref.data, children = _ref.children;
  var music = (_data$mdx$frontmatter = data.mdx.frontmatter.music) === null || _data$mdx$frontmatter === void 0 ? void 0 : _data$mdx$frontmatter.publicURL;
  React.useEffect(function () {
    if (data.mdx.frontmatter.story_start) {
      saveGameName(data.mdx.frontmatter.title);
    }
  }, []);
  saveLevelState(data.mdx.frontmatter.slug);
  React.useEffect(function () {
    saveLevelState(data.mdx.frontmatter.slug);
  }, [data.mdx.frontmatter.slug]);
  var musicIsAlreadyPlaying = Howler._howls.find(function (howl) {
    return howl._src === music && howl.playing();
  });
  if (!musicIsAlreadyPlaying) {
    Howler.stop();
    if (music) {
      var _useSound = useSound(music, {
        loop: true
      }), play = _useSound[0], _useSound$ = _useSound[1], stop = _useSound$.stop, _Howl = _useSound$.sound;
      play();
    }
  }
  return React.createElement(Layout, {
    pageTitle: data.mdx.frontmatter.title
  }, children);
};
var query = "1069625164";
export var Head = function Head(_ref2) {
  var data = _ref2.data;
  return React.createElement(Seo, {
    title: data.mdx.frontmatter.title
  });
};
GamePage
export default function GatsbyMDXWrapper(props) {
  return React.createElement(GamePage, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
